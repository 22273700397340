import { jsx as _jsx } from "react/jsx-runtime";
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { $Paperwork } from '../../state/PaperworkState';
import { SelectField } from '../atoms/SelectField';
export const ChannelField = ({ isLoading, context, channels, handleChange, setFieldValue, valueInput, }) => {
    const dispatch = useAppDispatch();
    return (_jsx(SelectField, { label: "Rubinetto", name: "channelInput", disabled: isLoading, onChange: e => {
            handleChange(e);
            const channel = channels.find(({ label }) => label === e.target.value);
            if (!channel) {
                return;
            }
            dispatch($Paperwork.fetchInstitutes({
                channel: channel.value,
                paperworkId: context.artooId,
                productTypology: context.productTypology,
            }));
            setFieldValue('channel', channel.value);
        }, options: channels, value: valueInput, required: true, helperText: "La modifica di questo valore pu\u00F2 condizionare i campi relativi ai prodotti" }));
};
