import * as Yup from 'yup';
export const CqsSchema = Yup.object().shape({
    cessione_del_quinto_finalita: Yup.string().required(),
    cessione_del_quinto_tipo_di_operazione: Yup.string().required(),
    cessione_del_quinto_tipo_di_dipendente: Yup.string().required(),
    cessione_del_quinto_finanziaria: Yup.string().required(),
    cessione_del_quinto_prodotto: Yup.string().required(),
    cessione_del_quinto_importo_richiesto: Yup.string().required(),
    cessione_del_quinto_taeg: Yup.string().required(),
    cessione_del_quinto_punti_applicati: Yup.string().required(),
    cessione_del_quinto_durata_mesi: Yup.string().required(),
    cessione_del_quinto_rata: Yup.string().required(),
    cessione_del_quinto_montante: Yup.string().required(),
    cessione_del_quinto_montante_residuo: Yup.string().required(),
    cessione_del_quinto_delta_montante: Yup.string().required(),
    cessione_del_quinto_monte_interessi: Yup.string().required(),
});
