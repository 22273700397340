import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import { makeStyles } from 'tss-react/mui';
import ArrowBackIcon from '../../../../assets/icons/arrowBack.svg';
import { useActiveScroll } from '../../../hooks/useActiveScroll';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { $Paperwork } from '../../state/PaperworkState';
import { ContactBox } from '../atoms/ContactBox';
export const useStyles = makeStyles()(theme => ({
    scroll: {
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.15)',
    },
    sticky: {
        background: theme.palette.common.white,
        position: 'sticky',
        top: 0,
        zIndex: 1,
        height: 80,
        display: 'flex',
        alignItems: 'center',
        paddingRight: theme.spacing(5),
        paddingLeft: theme.spacing(5),
    },
    mr5: {
        marginRight: theme.spacing(5),
    },
    mt3: {
        marginTop: theme.spacing(3),
    },
    cursorPointer: {
        cursor: 'pointer',
    },
    alignItemsFirst: {
        alignItems: 'first baseline',
    },
    alignItemsCenter: {
        alignItems: 'center',
    },
}));
const handleArrowBackClick = (dispatch) => () => {
    dispatch($Paperwork.goBack());
};
export const Navbar = () => {
    const { classes } = useStyles();
    const scrollActive = useActiveScroll('scroll-navbar');
    const dispatch = useAppDispatch();
    return (_jsx("div", { className: clsx(classes.sticky, scrollActive && classes.scroll), children: _jsx(Grid, { container: true, wrap: "nowrap", className: classes.alignItemsCenter, children: _jsxs(Grid, { container: true, item: true, className: clsx(classes.cursorPointer, classes.alignItemsFirst), wrap: "nowrap", children: [_jsx(Grid, { item: true, children: _jsx("div", { onClick: handleArrowBackClick(dispatch), children: _jsx(ArrowBackIcon, { className: classes.mr5 }) }) }), _jsx(Grid, { item: true, children: _jsxs(Grid, { container: true, direction: "column", children: [_jsx(Typography, { variant: "h2", children: "nome e cognome" }), _jsx("div", { className: classes.mt3, children: _jsx(ContactBox, { phone: "phone", email: "email" }) })] }) })] }) }) }));
};
