import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { $Paperwork } from '../../state/PaperworkState';
import { AutocompleteField } from '../atoms/AutocompleteField';
import { CheckboxField } from '../atoms/CheckboxField';
import { CurrencyField } from '../atoms/CurrencyField';
import { DateField } from '../atoms/DateField';
import { InputField } from '../atoms/InputField';
import { SelectField } from '../atoms/SelectField';
import { Subtitle } from '../atoms/Subtitle';
import { Title } from '../atoms/Title';
export const ParticipantForm = ({ values, handleChange, setFieldValue, placeOfResidence, handleBlur, isResidenceLocationLoading, }) => {
    const dispatch = useAppDispatch();
    return (_jsxs(_Fragment, { children: [_jsx(Title, { children: "Primo intestatario" }), _jsx(Subtitle, { children: "Dati anagrafici" }), _jsx(InputField, { name: "participant.firstName", label: "Nome", onChange: handleChange, value: values.participant.firstName, required: true }), _jsx(InputField, { name: "participant.lastName", label: "Cognome", onChange: handleChange, value: values.participant.lastName, required: true }), _jsx(InputField, { name: "participant.residence.address", label: "Indirizzo di residenza", onChange: handleChange, value: values.participant.residence.address, required: true }), _jsx(AutocompleteField, { required: true, name: "participant.residence.location", inputName: "participant.residence.locationInput", label: "Localit\u00E0 di residenza", noOptionsText: "Nessuna localit\u00E0 di residenza disponibile", options: placeOfResidence, setFieldValue: setFieldValue, isOptionEqualToValue: (option1, currentValue) => option1 === currentValue, getOptionLabel: location => {
                    if (typeof location === 'string') {
                        return '';
                    }
                    return location.name === '' || location.province.shortName === ''
                        ? ''
                        : `${location.name} (${location.province.shortName})`;
                }, onInputChange: newInputValue => {
                    if (/^(.*)\s\(.*\)$/i.test(newInputValue) === false) {
                        return dispatch($Paperwork.fetchLocation({ search: newInputValue }));
                    }
                }, value: values.participant.residence.location, inputValue: values.participant.residence.locationInput, handleBlur: handleBlur, isLoading: isResidenceLocationLoading }), _jsx(InputField, { name: "participant.residence.postalCode", label: "CAP di residenza", onChange: handleChange, value: values.participant.residence.postalCode, required: true }), _jsx(InputField, { name: "participant.cityOfBirth", label: "Comune di nascita", onChange: handleChange, value: values.participant.cityOfBirth, required: true }), _jsx(InputField, { name: "participant.provinceOfBirth", label: "Provincia di nascita", onChange: handleChange, value: values.participant.provinceOfBirth, required: true }), _jsx(DateField, { name: "participant.birthday", label: "Data di nascita", onChange: newDate => {
                    if (!newDate) {
                        return;
                    }
                    setFieldValue('participant.birthday', newDate.toISOString());
                }, value: new Date(values.participant.birthday), required: true }), _jsx(SelectField, { onChange: handleChange, value: values.participant.gender, required: true, name: "participant.gender", label: "Sesso", options: ['M', 'F'] }), _jsx(InputField, { name: "participant.residence.fiscalCode", label: "Codice fiscale", onChange: handleChange, value: values.participant.fiscalCode, required: true }), _jsx(SelectField, { onChange: handleChange, value: values.participant.identityDocument.type, required: true, name: "participant.identityDocument.type", label: "Tipo di documento", options: ['Carta di identità', 'Patente', 'Passaporto', 'Altro'] }), _jsx(SelectField, { onChange: handleChange, value: values.participant.citizenship, required: true, name: "participant.citizenship", label: "Cittadinanza", options: ['Italiano', 'Straniero'] }), _jsx(InputField, { name: "participant.identityDocument.code", label: "N\u00B0 di documento", onChange: handleChange, value: values.participant.identityDocument.code, required: true }), _jsx(InputField, { name: "participant.identityDocument.issuedBy", label: "Rilasciato da", onChange: handleChange, value: values.participant.identityDocument.issuedBy, required: true }), _jsx(InputField, { name: "participant.identityDocument.placeOfIssue", label: "Luogo di rilascio", onChange: handleChange, value: values.participant.identityDocument.placeOfIssue, required: true }), _jsx(DateField, { label: "Data di rilascio", name: "participant.identityDocument.issueDate", value: new Date(values.participant.identityDocument.issueDate), required: true, onChange: newDate => {
                    if (!newDate) {
                        return;
                    }
                    setFieldValue('participant.identityDocument.issueDate', newDate.toISOString());
                } }), _jsx(DateField, { label: "Data di scadenza", name: "participant.identityDocument.expiryDate", value: new Date(values.participant.identityDocument.expiryDate), onChange: newDate => {
                    if (!newDate) {
                        return;
                    }
                    setFieldValue('participant.identityDocument.expiryDate', newDate.toISOString());
                }, required: true }), _jsx(Subtitle, { children: "Contatti" }), _jsx(InputField, { name: "participant.contacts.mobilePhone", label: "Telefono cellulare", onChange: handleChange, value: values.participant.contacts.mobilePhone, startAdornmentLabel: "+39", required: true }), _jsx(InputField, { name: "participant.contacts.landline", label: "Telefono fisso", onChange: handleChange, value: values.participant.contacts.landline, startAdornmentLabel: "+39" }), _jsx(InputField, { name: "participant.contacts.email", label: "Email", onChange: handleChange, value: values.participant.contacts.email, required: true }), _jsx(Subtitle, { children: "Dati reddituali" }), _jsx(SelectField, { onChange: handleChange, value: values.participant.maritalStatus, required: true, name: "participant.maritalStatus", label: "Stato civile", options: [
                    'Celibe/Nubile',
                    'Coniugato/a',
                    'Coniugato/a c.b.',
                    'Coniugato/a s.b.',
                    'Separato/a',
                    'Vedovo/a',
                    'Divorziato/a',
                    'Convivente',
                    'Libero/a',
                    'Coniugato/a con figli',
                    'Convivente con figli',
                ] }), _jsx(SelectField, { onChange: handleChange, value: values.participant.job, required: true, name: "participant.job", label: "Professione", options: [
                    'Dipendente a tempo indeterminato',
                    'Dipendente a tempo determinato',
                    'Autonomo con P.IVA',
                    'Lavoratore autonomo',
                    'Libero professionista',
                    'Pensionato',
                    'Casalinga',
                    'Studente',
                    'Reddito non dimostrabile',
                    'Disoccupato',
                    'Amministratore di società',
                    'Colf/Collaboratore',
                    'Frontaliere',
                    'Altro',
                ] }), _jsx(SelectField, { onChange: handleChange, value: values.participant.companyType, name: "participant.companyType", label: "Tipo di azienda", options: [
                    'S.p.a.',
                    'S.r.l. fino a 30 dipendenti',
                    'S.r.l. con più di 30 dipendenti',
                    'S.n.c.',
                    'S.a.s.',
                    'Coop Fino a 200 dipendenti',
                    'Coop con più di 200 dipendenti',
                    'Ditta Individuale',
                    'Onlus o fondazioni private',
                    'Altro',
                ], required: true }), _jsx(InputField, { name: "participant.employerVatNumber", label: "P.IVA datore di lavoro", onChange: handleChange, value: values.participant.employerVatNumber }), _jsx(CurrencyField, { fieldLabel: "Reddito mensile netto", name: "participant.netIncome", onValueChange: ({ floatValue }) => {
                    setFieldValue('participant.netIncome', floatValue);
                }, value: values.participant.netIncome, required: true }), _jsx(InputField, { name: "participant.monthlyInstallment", label: "Numero di mensilit\u00E0", onChange: handleChange, value: values.participant.monthlyInstallment, required: true }), _jsx(InputField, { name: "participant.lengthOfService", label: "Anzianit\u00E0 lavorativa", onChange: handleChange, value: values.participant.lengthOfService, endAdornmentLabel: "anni", required: true }), _jsx(SelectField, { onChange: handleChange, value: values.participant.household, required: true, name: "participant.household", label: "Componenti nucleo familiare", options: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'] }), _jsx(Subtitle, { children: "Consensi" }), _jsx(CheckboxField, { name: "participant.marketing", value: values.participant.marketing, label: "Consenso per l'invio di comunicazioni promozionali e di marketing", setFieldValue: setFieldValue }), _jsx(CheckboxField, { name: "participant.thirdParties", value: values.participant.thirdParties, label: "Consenso per la cessione di dati a terzi", setFieldValue: setFieldValue })] }));
};
