import { jsx as _jsx } from "react/jsx-runtime";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { makeStyles } from 'tss-react/mui';
const useStyles = makeStyles()(() => ({
    label: {
        paddingRight: '10px',
        backgroundColor: 'white',
    },
}));
export const DateField = ({ name, value, onChange, label, required = false, disabled = false, helperText, onClear, }) => {
    const { classes } = useStyles();
    return (_jsx(DatePicker, { name: name, value: value, disabled: disabled, slotProps: {
            field: { clearable: true, onClear: onClear },
            textField: {
                InputLabelProps: {
                    className: classes.label,
                },
                margin: 'dense',
                fullWidth: true,
                required: required,
                helperText: helperText,
            },
        }, label: label, onChange: onChange }));
};
