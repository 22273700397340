import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Grid } from '@mui/material';
import clsx from 'clsx';
import { useEffect } from 'react';
import { useStyles } from './Prompt.styles';
import { PromptUtil } from './PromptUtil';
export const Warning = ({ isLoading, beforeCancelNode, setWarning, onConfirm }) => {
    const { classes } = useStyles();
    useEffect(() => {
        return () => setWarning(false);
    }, [setWarning]);
    return (_jsxs(Grid, { container: true, style: { justifyContent: 'center' }, children: [_jsx("div", { className: clsx(classes.displayFlex, classes.alignItemsCenter, classes.mr30px), children: beforeCancelNode }), _jsx(Button, { variant: "text", color: "primary", disabled: isLoading, onClick: PromptUtil.toggle(setWarning), children: "No" }), _jsx(Button, { className: classes.btn, variant: "contained", color: "secondary", disableElevation: true, disabled: isLoading, onClick: onConfirm, children: "Si" })] }));
};
