import { productTypologyModelMapper } from './productTypologyModelMapper';
export const paperworkModelMapper = (paperwork) => ({
    data: paperwork.data,
    details: {
        id: paperwork.id,
        productTypology: productTypologyModelMapper(paperwork.productType),
        assignedTo: paperwork.assignedTo,
        createdAt: paperwork.createdAt,
        createdBy: paperwork.createdBy,
        artooId: paperwork.artooId,
    },
});
