import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Container, Drawer, Grid, Paper } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { $Paperwork, $PaperworkSlice } from '../../state/PaperworkState';
import { Navbar } from '../molecules/Navbar';
import { CqsFormMainSection } from '../organisms/CqsFormMainSection';
import { Sidebar } from '../organisms/Sidebar';
export const PaperworkPage = () => {
    const { paperworkId } = useParams();
    const paperwork = useAppSelector($PaperworkSlice.selector.paperwork);
    const initialValues = useAppSelector($PaperworkSlice.selector.initialValues);
    const dispatch = useAppDispatch();
    const [openCqsDrawer, setOpenCqsDrawer] = useState(false);
    useEffect(() => {
        dispatch($Paperwork.start({
            paperworkId,
        }));
        return () => {
            dispatch($Paperwork.stop());
        };
    }, [dispatch, paperworkId]);
    return (_jsxs(_Fragment, { children: [_jsx(Navbar, {}), _jsx(Container, { sx: { marginTop: '32px' }, children: _jsxs(Grid, { container: true, item: true, flexWrap: "nowrap", children: [paperwork ? (_jsx(Sidebar, { paperworkDetail: paperwork.details })) : (_jsx(Container, { component: Paper, elevation: 0, sx: { width: '690px', padding: '32px 16px', minHeight: '100vh', marginRight: '16px' } })), _jsxs(Container, { maxWidth: "md", component: Paper, elevation: 0, sx: { padding: '32px 16px', minHeight: '100vh' }, children: [_jsx(Button, { variant: "contained", disabled: !paperwork || !initialValues, onClick: () => {
                                        setOpenCqsDrawer(true);
                                    }, children: "Modifica" }), _jsx(Container, { children: _jsx(Drawer, { open: openCqsDrawer, anchor: "right", children: paperwork && initialValues && (_jsx(CqsFormMainSection, { paperwork: paperwork, initialValues: initialValues, onClose: () => {
                                                setOpenCqsDrawer(false);
                                            } })) }) })] })] }) })] }));
};
