import { ALL_FILTER, FILTER } from '../../constants';
import { LocalStorage } from '../../lib/localStorage';
export const defaultStatusObject = {
    code: ALL_FILTER.STATES,
    name: ALL_FILTER.STATES,
};
export const defaultAssigneeObject = {
    id: ALL_FILTER.ASSIGNEE,
    first_name: ALL_FILTER.ASSIGNEE,
    last_name: '',
};
const savedSearch = LocalStorage.getItem(FILTER.RECORDS);
const parsedSavedSearch = savedSearch && JSON.parse(savedSearch);
const empty = {
    error: '',
    message: '',
    isLoading: false,
    closedRecords: parsedSavedSearch
        ? Object.assign(Object.assign({}, parsedSavedSearch.closedRecords), { hidden: 1 }) || { hidden: 1 }
        : { hidden: 1 },
    activeRecords: parsedSavedSearch ? parsedSavedSearch.activeRecords || {} : {},
    url: parsedSavedSearch ? parsedSavedSearch.url || '' : '',
    isClosedRecordsSearching: false,
    isActiveRecordsSearching: false,
    isAssigneeLoading: false,
    isStatusLoading: false,
    status: [],
    assignee: [],
    canViewAgentsRecordsFilter: { isVisible: false, hasError: null },
};
const defaultAssignee = (state) => [
    {
        original_id: ALL_FILTER.ASSIGNEE,
        first_name: ALL_FILTER.ASSIGNEE,
        last_name: '',
    },
    ...state.filter.assignee,
];
const defaultStatus = (state) => [
    {
        name: ALL_FILTER.STATES,
        code: ALL_FILTER.STATES,
    },
    ...state.filter.status,
];
export const FilterState = {
    empty,
    selector: {
        defaultStatus: defaultStatus,
        defaultAssignee: defaultAssignee,
    },
};
