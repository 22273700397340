var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { number, ord, readonlyArray } from 'fp-ts';
import { isNone, isSome } from 'fp-ts/Option';
import { pipe } from 'fp-ts/function';
import { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { ProcessState } from '../store/processes/ProcessState';
import { SecurityActions } from '../store/security/SecurityActions';
import { SecurityState } from '../store/security/SecurityState';
import { ConnectedComponent } from '../ui/ConnectedComponent';
import { Layout } from '../ui/Layout/LayoutComponent';
import LoginContainer from '../ui/Login';
import { $routes } from './routes';
const PrivateRoute = (_a) => {
    var { isLoading, isLoggedIn, getSession, component: Component, firstRun, hasLoggedOut, auth, logout, message, loginLogoutProcess, isForbidden, isLoggingOut, isLoggingIn, isAuthorized } = _a, props = __rest(_a, ["isLoading", "isLoggedIn", "getSession", "component", "firstRun", "hasLoggedOut", "auth", "logout", "message", "loginLogoutProcess", "isForbidden", "isLoggingOut", "isLoggingIn", "isAuthorized"]);
    useEffect(() => {
        if (isLoggedIn || hasLoggedOut || isLoggingIn || isLoggingOut) {
            return;
        }
        getSession();
    }, [isLoggedIn, getSession, hasLoggedOut, isLoggingIn, isLoggingOut]);
    useEffect(() => {
        const loginP = pipe(loginLogoutProcess, readonlyArray.findFirst(p => p.name === SecurityActions.Login.name));
        const logoutP = pipe(loginLogoutProcess, readonlyArray.findFirst(p => p.name === SecurityActions.Logout.name));
        if (isForbidden) {
            if ((isSome(loginP) &&
                isSome(logoutP) &&
                ord.gt(number.Ord)(loginP.value.timestamp, logoutP.value.timestamp)) ||
                isNone(logoutP)) {
                logout();
            }
        }
        else if (isSome(loginP) && isSome(logoutP)) {
            // se sono state fatte piu azioni di login/logout
            // verifico che il ts del login sia piu recente di quello di logout
            // se è piu recente e c'e' un errore, procedo con il logout,
            if (ord.gt(number.Ord)(loginP.value.timestamp, logoutP.value.timestamp)) {
                if (isSome(auth)) {
                    // Se non esistono gia altri process di logout in corso procedo con il logout
                    if (!isLoggingOut) {
                        logout(auth.value);
                    }
                }
            }
            // se è la prima azione di logout
            // e c'e' un errore
            // procedo con logout
        }
        else if (isNone(logoutP)) {
            if (isSome(auth)) {
                logout(auth.value);
            }
        }
    }, [auth, logout, loginLogoutProcess, isForbidden, isLoggingOut]);
    return (_jsx(Route, Object.assign({}, props, { render: routeProps => {
            if (isLoading || firstRun) {
                return _jsx(_Fragment, {});
            }
            else if (isLoggedIn && isNone(message)) {
                return isAuthorized ? (_jsx(Layout, { component: Component, props: routeProps })) : (_jsx(Redirect, { to: $routes.HOME }));
            }
            return _jsx(LoginContainer, {});
        } })));
};
export default ConnectedComponent(state => ({
    isLoading: ProcessState.selector.has.running(SecurityActions.Session)(state),
    hasLoggedOut: ProcessState.selector.has.done(SecurityActions.Logout)(state),
    isLoggedIn: SecurityState.selector.isLoggedIn(state),
    firstRun: SecurityState.selector.firstRun(state),
    auth: SecurityState.selector.auth(state),
    isForbidden: SecurityState.selector.isForbidden(state),
    isLoggingOut: ProcessState.selector.has.running(SecurityActions.Logout)(state),
    isLoggingIn: ProcessState.selector.has.running(SecurityActions.Login)(state),
    message: state.security.message,
    loginLogoutProcess: ProcessState.selector.processes(SecurityActions.Logout, SecurityActions.Login)(state),
}), { getSession: SecurityActions.Session, logout: SecurityActions.Logout })(PrivateRoute);
