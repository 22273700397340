import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormControl, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
const useStyles = makeStyles()(() => ({
    label: {
        paddingRight: '10px',
        backgroundColor: 'white',
    },
}));
export const InputField = ({ endAdornmentLabel, startAdornmentLabel, label, name, onChange, value, disabled = false, required = false, type = 'text', }) => {
    const { classes } = useStyles();
    return (_jsxs(FormControl, { margin: "dense", fullWidth: true, disabled: disabled, required: required, variant: "outlined", children: [_jsx(InputLabel, { className: classes.label, children: label }), _jsx(OutlinedInput, { type: type, label: label, name: name, onChange: onChange, value: value, endAdornment: endAdornmentLabel ? _jsx(InputAdornment, { position: "end", children: endAdornmentLabel }) : undefined, startAdornment: startAdornmentLabel ? (_jsx(InputAdornment, { position: "start", children: startAdornmentLabel })) : undefined })] }));
};
