import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { DateField } from '../atoms/DateField';
import { SelectAutocompleteField } from '../atoms/SelectAutocompleteField';
const LENDING_DATE = 'lendingDate';
export const LendingSection = ({ setFieldValue, value }) => {
    return (_jsxs(_Fragment, { children: [_jsx(DateField, { label: "Data erogazione finanziamento", name: LENDING_DATE, value: new Date(value), onChange: newDate => {
                    if (!newDate) {
                        return;
                    }
                    setFieldValue(LENDING_DATE, newDate.toISOString());
                } }), _jsx(SelectAutocompleteField, { label: "Mezzo di pagamento finanziamento", name: "lendingPaymentMethod", noOptionsText: "Nessun mezzo di pagamento finanziamento disponibile", options: [
                    'Accredito su conto corrente',
                    'Assegno circolare',
                    'Bonifico bancario',
                    'Disposizione irrevocabile',
                ] })] }));
};
