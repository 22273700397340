import { jsx as _jsx } from "react/jsx-runtime";
import { Autocomplete, TextField } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
const useStyles = makeStyles()(() => ({
    label: {
        paddingRight: '10px',
        backgroundColor: 'white',
    },
}));
export const SelectAutocompleteField = ({ name, label, options, noOptionsText, required = false }) => {
    const { classes } = useStyles();
    return (_jsx(Autocomplete, { renderInput: params => (_jsx(TextField, Object.assign({}, params, { InputLabelProps: { className: classes.label }, label: label, name: name, required: required }))), noOptionsText: noOptionsText, options: options }));
};
