import { BazaarQuotationUtils } from '../../models/BazaarQuotation.codec';
import { SUGGESTED_BROKERAGE_PERCENTAGE } from '../../models/SuggestedBrokerageModel';
import { mortgagePurposeView } from '../../view/MortgagePurposeView';
import { applicantStateMapper } from './applicantMapper';
import { guarantorStateMapper } from './guarantorMapper';
export const latestMediationAmount = (quotations) => {
    const latestQuotation = quotations
        .map(quotation => (Object.assign(Object.assign({}, quotation), { submittedAt: new Date(quotation.submittedAt) })))
        .sort((firstQuotation, secondQuotation) => secondQuotation.submittedAt.getTime() - firstQuotation.submittedAt.getTime())[0];
    if (latestQuotation) {
        return latestQuotation.feasibilityConditionsAndOutcomes.mediationAmount.value;
    }
    return '';
};
const calculateMediation = (requestWithQuotes) => {
    if (requestWithQuotes.quotations.length > 0) {
        return latestMediationAmount(requestWithQuotes.quotations);
    }
    else if (requestWithQuotes.leftoverAmount) {
        return SUGGESTED_BROKERAGE_PERCENTAGE * requestWithQuotes.leftoverAmount;
    }
    return requestWithQuotes.requestedAmount ? requestWithQuotes.requestedAmount * SUGGESTED_BROKERAGE_PERCENTAGE : '';
};
const tempMediation = (requestWithQuotes) => {
    if (requestWithQuotes.quotations.length > 0) {
        return latestMediationAmount(requestWithQuotes.quotations);
    }
    return '';
};
export const searchStateMapper = (requestWithQuotesModel) => {
    var _a, _b;
    return {
        leadId: requestWithQuotesModel.lead.id,
        channel: requestWithQuotesModel.lead.channel,
        email: requestWithQuotesModel.lead.email,
        firstName: requestWithQuotesModel.lead.firstName,
        lastName: requestWithQuotesModel.lead.lastName,
        phoneNumber: requestWithQuotesModel.lead.phoneNumber,
        filters: {
            rate: 'fixed',
            institutes: [],
            attributes: [],
        },
        rate: BazaarQuotationUtils.rate.FIXED,
        procedureStatus: requestWithQuotesModel.procedureStatus,
        productType: 'Mutuo',
        mortgageDuration: requestWithQuotesModel.duration,
        formattedLeftover: requestWithQuotesModel.leftoverAmount ? requestWithQuotesModel.leftoverAmount : '',
        rawLeftover: (_b = (_a = requestWithQuotesModel.leftoverAmount) === null || _a === void 0 ? void 0 : _a.toString()) !== null && _b !== void 0 ? _b : '',
        formattedRealEstateActualValue: requestWithQuotesModel.property.value,
        rawRealEstateActualValue: requestWithQuotesModel.property.value,
        realEstateLocation: {
            name: requestWithQuotesModel.property.location.city,
            postalCode: requestWithQuotesModel.property.location.postalCode,
            province: { shortName: requestWithQuotesModel.property.location.provinceCode },
        },
        realEstateLocationInput: `${requestWithQuotesModel.property.location.city} (${requestWithQuotesModel.property.location.provinceCode})`,
        formattedRealEstateRequestedAmount: requestWithQuotesModel.requestedAmount
            ? requestWithQuotesModel.requestedAmount
            : '',
        rawRealEstateRequestedAmount: requestWithQuotesModel.requestedAmount
            ? requestWithQuotesModel.requestedAmount
            : '',
        formattedBrokerage: calculateMediation(requestWithQuotesModel),
        rawBrokerage: calculateMediation(requestWithQuotesModel),
        tempFormattedBrokerage: tempMediation(requestWithQuotesModel),
        tempRawBrokerage: tempMediation(requestWithQuotesModel),
        mortgagePurpose: mortgagePurposeView(requestWithQuotesModel.purpose),
        participants: [
            ...requestWithQuotesModel.applicants.map(applicantStateMapper),
            ...requestWithQuotesModel.guarantors.map(guarantorStateMapper),
        ],
        notes: requestWithQuotesModel.notes,
        tempNotes: requestWithQuotesModel.notes,
    };
};
