import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { Box, Container, Divider, Drawer, Grid, Link, Paper, Typography } from '@mui/material';
import { useState } from 'react';
import { PaperworkDetail } from '../molecules/PaperworkDetail';
export const Sidebar = ({ paperworkDetail }) => {
    const [openPaperworkDetailDrawer, setOpenPaperworkDetailDrawer] = useState(false);
    return (_jsxs(Container, { maxWidth: "xs", component: Paper, elevation: 0, sx: { padding: '32px 16px', minHeight: '100vh', marginRight: '16px' }, children: [_jsx(Container, { children: _jsx(Drawer, { open: openPaperworkDetailDrawer, anchor: "right", children: _jsx(PaperworkDetail, { onClose: () => setOpenPaperworkDetailDrawer(false) }) }) }), _jsx(Box, { sx: { p: '4px 4px', bgcolor: '#f2f2f2', borderRadius: '4px' }, component: "span", children: _jsxs(Typography, { component: "span", children: ["ID: ", paperworkDetail.artooId] }) }), _jsxs(Grid, { container: true, alignItems: "center", justifyContent: "space-between", flexWrap: "nowrap", sx: { mb: '8px', mt: '16px' }, children: [_jsxs(Grid, { container: true, item: true, alignItems: "center", flexWrap: "nowrap", children: [_jsx(DescriptionOutlinedIcon, {}), _jsx(Typography, { variant: "h3", component: "span", sx: { marginLeft: '8px' }, children: "Gestione pratica" })] }), _jsx(Grid, { container: true, item: true, justifyContent: "flex-end", xs: 3, children: _jsx(Typography, { children: _jsx(Link, { underline: "none", onClick: () => {
                                    setOpenPaperworkDetailDrawer(true);
                                }, children: "Dettagli" }) }) })] }), _jsx(Divider, { sx: { mt: '8px' } })] }));
};
