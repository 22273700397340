import { either } from 'fp-ts';
import { push } from 'redux-first-history';
import { all, call, cancel, debounce, put, take, takeLeading } from 'typed-redux-saga';
import { $routes } from '../../routes/routes';
import { $Paperwork } from './PaperworkState';
function* navigateBack() {
    yield* put(push($routes.ACTIVE_RECORDS));
}
function* fetchChannels(paperworkService) {
    yield* put($Paperwork.ChannelsRequested());
    const channelsEither = yield* call(paperworkService.getChannels());
    if (either.isLeft(channelsEither)) {
        yield* put($Paperwork.ChannelsNotFetched());
        return new Error('channels not fetched');
    }
    yield* put($Paperwork.ChannelsFetched({ channels: channelsEither.right }));
    return channelsEither.right;
}
function* fetchInstitutes(paperworkService, payload) {
    yield* put($Paperwork.InstitutesRequested());
    const institutesEither = yield* call(paperworkService.getInstitutes({
        productTypology: payload.productTypology,
        channel: payload.channel,
        paperworkId: payload.paperworkId,
    }));
    if (either.isLeft(institutesEither)) {
        yield* put($Paperwork.InstitutesNotFetched());
        return new Error('institutes not fetched');
    }
    yield* put($Paperwork.InstitutesFetched({ institutes: institutesEither.right }));
    return institutesEither.right;
}
function* fetchProducts(paperworkService, payload) {
    yield* put($Paperwork.ProductsRequested());
    const productsEither = yield* call(paperworkService.getProducts({
        institute: payload.institute,
        productTypology: payload.productTypology,
        channel: payload.channel,
        paperworkId: payload.paperworkId,
    }));
    if (either.isLeft(productsEither)) {
        yield* put($Paperwork.ProductsNotFetched());
        return new Error('Products not fetched');
    }
    yield* put($Paperwork.ProductsFetched({ products: productsEither.right }));
    return productsEither.right;
}
function* fetchStores(paperworkService, search) {
    yield* put($Paperwork.StoresRequested());
    const storesEither = yield* call(paperworkService.getStores(search));
    if (either.isLeft(storesEither)) {
        yield* put($Paperwork.StoresNotFetched());
        return;
    }
    yield* put($Paperwork.StoresFetched({ stores: storesEither.right }));
}
function* fetchLocation(paperworkService, search) {
    yield* put($Paperwork.LocationRequested());
    const locationEither = yield* call(paperworkService.getLocalities({ search }));
    if (either.isLeft(locationEither)) {
        yield* put($Paperwork.LocationNotFetched());
        return;
    }
    yield* put($Paperwork.LocationFetched({ participantPlaceOfResidence: locationEither.right }));
}
function* updatePaperwork(paperworkService, formData, paperworkId) {
    yield* put($Paperwork.PaperworkUpdateRequested());
    const paperworkEither = yield* call(paperworkService.updatePaperwork({ formData: formData, paperworkId: paperworkId }));
    if (either.isLeft(paperworkEither)) {
        yield* put($Paperwork.PaperworkNotUpdated());
        return;
    }
    yield* put($Paperwork.PaperworkUpdated());
}
function* fetchPaperwork(paperworkService, payload) {
    yield* put($Paperwork.PaperworkRequested());
    const paperworkEither = yield* call(paperworkService.getPaperwork({ paperworkId: payload.paperworkId }));
    if (either.isLeft(paperworkEither)) {
        yield* put($Paperwork.PaperworkNotFetched());
        return new Error('paperwork not fetched');
    }
    yield* put($Paperwork.PaperworkFetched({ paperwork: paperworkEither.right }));
    return paperworkEither.right;
}
function* fillForm(paperworkService, paperwork) {
    yield* put($Paperwork.FormFillRequested());
    const { channels, institutes, products } = yield* all(Object.assign(Object.assign({ channels: call(fetchChannels, paperworkService) }, (paperwork.data.rubinetto !== '' &&
        paperwork.data.cessione_del_quinto_finanziaria !== '' && {
        institutes: call(fetchInstitutes, paperworkService, {
            channel: paperwork.data.tipo_rubinetto_raw,
            productTypology: paperwork.details.productTypology,
            paperworkId: paperwork.details.id,
        }),
    })), (paperwork.data.rubinetto !== '' &&
        paperwork.data.cessione_del_quinto_finanziaria &&
        paperwork.data.cessione_del_quinto_finanziaria !== '' &&
        paperwork.data.istituto &&
        paperwork.data.istituto !== '' && {
        products: call(fetchProducts, paperworkService, {
            channel: paperwork.data.tipo_rubinetto_raw,
            productTypology: paperwork.details.productTypology,
            paperworkId: paperwork.details.id,
            institute: paperwork.data.istituto,
        }),
    })));
    if (channels instanceof Error || institutes instanceof Error || products instanceof Error) {
        yield* put($Paperwork.FormNotFilled());
        return;
    }
    yield* put($Paperwork.FormFilled({ paperwork: paperwork }));
}
export function* PaperworkSaga(paperworkService) {
    yield* takeLeading($Paperwork.start, function* (startCommand) {
        const paperworkId = startCommand.payload.paperworkId;
        if (!paperworkId) {
            return;
        }
        // const userPermissionList = yield* select(SecurityState.selector.permissionList)
        // if (isUserAuthorized(userPermissionList)()) {
        yield* put($Paperwork.Started());
        const task = yield* all([
            yield* takeLeading($Paperwork.goBack, navigateBack),
            yield* takeLeading($Paperwork.fetchInstitutes, function* (fetchInstitutesCommand) {
                yield* call(fetchInstitutes, paperworkService, {
                    channel: fetchInstitutesCommand.payload.channel,
                    productTypology: fetchInstitutesCommand.payload.productTypology,
                    paperworkId: fetchInstitutesCommand.payload.paperworkId,
                });
            }),
            yield* takeLeading($Paperwork.fetchProducts, function* (fetchProductsCommand) {
                yield* call(fetchProducts, paperworkService, {
                    channel: fetchProductsCommand.payload.channel,
                    paperworkId: fetchProductsCommand.payload.paperworkId,
                    institute: fetchProductsCommand.payload.institute,
                    productTypology: fetchProductsCommand.payload.productTypology,
                });
            }),
            yield* takeLeading($Paperwork.fillForm, function* (fillFormCommand) {
                yield* call(fillForm, paperworkService, fillFormCommand.payload.paperwork);
            }),
            yield* takeLeading($Paperwork.fetchChannels, function* () {
                yield* call(fetchChannels, paperworkService);
            }),
            yield* takeLeading($Paperwork.updatePaperwork, function* (updatePaperworkCommand) {
                yield* call(updatePaperwork, paperworkService, updatePaperworkCommand.payload.formData, paperworkId);
            }),
            yield* debounce(1000, $Paperwork.fetchStores, function* (fetchStoreCommand) {
                yield* call(fetchStores, paperworkService, fetchStoreCommand.payload.search);
            }),
            yield* debounce(1000, $Paperwork.fetchLocation, function* (fetchLocationCommand) {
                yield* call(fetchLocation, paperworkService, fetchLocationCommand.payload.search);
            }),
        ]);
        const paperwork = yield* call(fetchPaperwork, paperworkService, {
            paperworkId,
        });
        if (!(paperwork instanceof Error)) {
            yield* call(fillForm, paperworkService, paperwork);
        }
        yield* take($Paperwork.stop);
        yield* cancel(task);
        yield* put($Paperwork.Stopped());
        // }
    });
}
