import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Grid } from '@mui/material';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { $Paperwork } from '../../state/PaperworkState';
import { CurrencyField } from '../atoms/CurrencyField';
import { InputField } from '../atoms/InputField';
import { SelectField } from '../atoms/SelectField';
import { Title } from '../atoms/Title';
const INTEREST_DIFFERENCE = 'cqs.interestDifference';
const INTEREST = 'cqs.interest';
const INTEREST_LEFT = 'cqs.interestLeft';
const INSTALLMENT = 'cqs.installment';
const REQUESTED_AMOUNT = 'cqs.requestedAmount';
export const CqsFormProductSection = ({ handleChange, setFieldValue, values, products, institutes, isInstitutesLoading, isProductsLoading, paperworkId, }) => {
    const dispatch = useAppDispatch();
    return (_jsxs(_Fragment, { children: [_jsx(Title, { children: "Prodotto: CQS" }), _jsx(SelectField, { label: "Finalit\u00E0", name: "cqs.purpose", onChange: handleChange, options: ['Cessione del Quinto', 'Delega di Pagamento', 'TFS'], value: values.cqs.purpose, required: true }), _jsx(SelectField, { label: "Tipo di operazione", name: "cqs.type", onChange: handleChange, options: ['Nuovo', 'Rinnovo'], value: values.cqs.type, required: true }), _jsx(SelectField, { label: "Tipo di dipendente", name: "cqs.employeeType", onChange: handleChange, options: [
                    'Dipendente privato',
                    'Dipendente pubblico',
                    'Dipendente statale',
                    'Dipendente parapubblico',
                    'Pensionato',
                ], value: values.cqs.employeeType }), _jsx(SelectField, { label: "Finanziaria", name: "cqs.institute", onChange: e => {
                    dispatch($Paperwork.fetchProducts({
                        channel: values.channel,
                        institute: e.target.value,
                        paperworkId: paperworkId,
                        productTypology: 'CQS',
                    }));
                    setFieldValue('cqs.product', '');
                    handleChange(e);
                }, options: institutes.map(institute => institute.name), value: values.cqs.institute, disabled: isInstitutesLoading, required: true }), products.length > 0 && (_jsx(SelectField, { label: "Prodotto", name: "cqs.product", onChange: handleChange, options: products.map(product => product.name), value: values.cqs.product, disabled: isProductsLoading, required: true })), _jsx(CurrencyField, { fieldLabel: "Importo richiesto", name: REQUESTED_AMOUNT, required: true, value: values.cqs.requestedAmount, onValueChange: ({ floatValue }) => setFieldValue(REQUESTED_AMOUNT, floatValue) }), _jsxs(Grid, { container: true, wrap: "nowrap", spacing: 2, children: [_jsx(Grid, { item: true, children: _jsx(InputField, { type: "number", label: "TAN", name: "cqs.tan", onChange: handleChange, value: values.cqs.tan, endAdornmentLabel: "%", required: true }) }), _jsx(Grid, { item: true, children: _jsx(InputField, { type: "number", label: "Punti applicati", name: "cqs.points", onChange: handleChange, value: values.cqs.points, endAdornmentLabel: "%", required: true }) })] }), _jsx(SelectField, { label: "Durata (mesi)", name: "cqs.duration", onChange: e => {
                    handleChange(e);
                    if (values.cqs.installment === '') {
                        return;
                    }
                    const duration = Number(e.target.value);
                    setFieldValue(INTEREST, values.cqs.installment * duration);
                }, options: ['24', '36', '48', '60', '72', '84', '96', '108', '120'], value: values.cqs.duration, required: true }), _jsx(CurrencyField, { fieldLabel: "Rata", name: INSTALLMENT, value: values.cqs.installment, onValueChange: value => {
                    setFieldValue(INSTALLMENT, value.floatValue);
                    if (!value.floatValue || values.cqs.duration === '') {
                        return;
                    }
                    const duration = Number(values.cqs.duration);
                    const interest = value.floatValue * duration;
                    setFieldValue(INTEREST, interest);
                    if (values.cqs.interestLeft === '') {
                        return;
                    }
                    const interestDifference = interest - values.cqs.interestLeft;
                    setFieldValue(INTEREST_DIFFERENCE, interestDifference);
                }, required: true }), _jsx(CurrencyField, { fieldLabel: "Montante", allowNegative: true, name: INTEREST, value: values.cqs.interest, required: true, disabled: true, onValueChange: interestValue => {
                    setFieldValue(INTEREST, interestValue.floatValue);
                    if (!interestValue.floatValue || values.cqs.interestLeft === '') {
                        return;
                    }
                    const interestDifference = interestValue.floatValue - values.cqs.interestLeft;
                    setFieldValue(INTEREST_DIFFERENCE, interestDifference);
                } }), values.cqs.type === 'Rinnovo' && (_jsx(CurrencyField, { fieldLabel: "Montante residuo", name: INTEREST_LEFT, value: values.cqs.interestLeft, required: true, onValueChange: interestValue => {
                    setFieldValue(INTEREST_LEFT, interestValue.floatValue);
                    if (!interestValue.floatValue || values.cqs.interest === '') {
                        return;
                    }
                    const interestDifference = values.cqs.interest - interestValue.floatValue;
                    setFieldValue(INTEREST_DIFFERENCE, interestDifference);
                } })), values.cqs.type === 'Rinnovo' && (_jsx(CurrencyField, { allowNegative: true, fieldLabel: "Delta montante", value: values.cqs.interestDifference, onValueChange: ({ floatValue }) => setFieldValue(INTEREST_DIFFERENCE, floatValue), name: INTEREST_DIFFERENCE, required: true, disabled: true }))] }));
};
