import { makeStyles } from 'tss-react/mui';
import { colors } from '../../theme/palette';
export const useStyles = makeStyles()(theme => ({
    iconSize: {
        width: 30,
        height: 30,
        marginTop: -13,
    },
    displayFlex: {
        display: 'flex',
    },
    alignItemsCenter: { alignItems: 'center' },
    separator: {
        left: 0,
        width: '100%',
        height: 1,
        backgroundColor: '#e5e5e5',
    },
    mt10px: {
        marginTop: 10,
    },
    mbNegative10px: {
        marginBottom: -10,
    },
    dialogContentRoot: {
        paddingTop: 0,
        paddingBottom: 0,
    },
    dialogTitleRoot: {
        paddingTop: 30,
        paddingBottom: 10,
    },
    btn: {
        marginLeft: theme.spacing(5),
    },
    mr30px: { marginRight: 30 },
    plr50px: {
        paddingLeft: 50,
        paddingRight: 50,
    },
    pt30px: {
        paddingTop: 30,
    },
    plr0: {
        paddingLeft: 0,
        paddingRight: 0,
    },
    plr20px: {
        paddingLeft: 20,
        paddingRight: 20,
    },
    titleBackground: {
        backgroundColor: colors.paleGrey,
        position: 'relative',
        '&:after': {
            content: '""',
            position: 'absolute',
            backgroundColor: colors.paleGrey,
            height: 116,
            width: '100%',
            left: 0,
            zIndex: 0,
        },
    },
    dialog: {
        '&>.MuiDialog-container': {
            '&>.MuiDialog-paper': {
                backgroundColor: '#fdfdfd',
            },
        },
    },
}));
