import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Create';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import { either, option, readonlyArray, readonlyRecord } from 'fp-ts';
import { constant, constFalse, pipe } from 'fp-ts/function';
import React, { useEffect, useState } from 'react';
import ApprovedFilled from '../../../assets/approvedFilled.svg';
import { Authorization } from '../../authorization/Authorization';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import { PERMISSION } from '../../store/security/SecurityState';
import { InvoiceStatusCodeUtils } from '../models/InvoiceStatusCode/InvoiceStatusCode';
import { $InvoiceDeletion, $invoiceDeletionSlice } from '../state/invoiceDeletion/InvoiceDeletionState';
import { $InvoiceEdit } from '../state/invoiceEdit/InvoiceEditState';
import { $invoiceListSlice } from '../state/invoiceList/InvoiceListState';
import { $InvoiceTransmission, $invoiceTransmissionSlice } from '../state/invoiceTransmission/InvoiceTransmissionState';
import { $InvoiceToTransmitTab } from '../state/tabs/invoiceToTransmitTab/InvoiceToTransmitTabState';
import { BulkEditDialog } from './BulkEditDialog';
import { EmptyList } from './EmptyList';
import { EnhancedTableToolbar } from './EnhancedTableToolbar';
import { InvoiceDetailDialog } from './InvoiceDetailDialog';
import { InvoiceSwitch } from './InvoiceSwitch';
import { NewInvoiceDialog } from './newInvoiceDialog/NewInvoiceDialog';
const squareButtonStyle = {
    maxWidth: 36,
    maxHeight: 36,
    minWidth: 36,
    minHeight: 36,
};
const handleDeleteInvoice = (invoiceDeleteAttempt, dispatch) => () => dispatch(invoiceDeleteAttempt);
const isDeleteButtonDisabled = (invoiceId) => (invoiceListLoading) => pipe(invoiceListLoading, readonlyRecord.lookup(invoiceId.toString()), option.getOrElse(constFalse));
const handleTransmitEveryInvoice = (invoiceBulkTransmissionAttempt, dispatch) => () => {
    dispatch(invoiceBulkTransmissionAttempt());
};
const handleEditInvoice = (invoiceId, startInvoiceEdit, dispatch, setNewInvoiceDialogOpen, setEditMode) => () => {
    setEditMode(true);
    dispatch(startInvoiceEdit(invoiceId));
    setNewInvoiceDialogOpen(open => !open);
};
const toggleModal = (stateSetter) => () => {
    stateSetter(open => !open);
};
const handleInvoiceDetail = (setInvoiceDetailDialogProps, invoiceId) => () => {
    setInvoiceDetailDialogProps(prevState => ({
        invoiceId: invoiceId,
        open: !prevState.open,
    }));
};
const handleInvoice = (transmitInvoiceRequest, dispatch) => () => dispatch(transmitInvoiceRequest);
const handleCloseInvoiceDetailDialog = (setInvoiceDetailDialogProps) => () => {
    setInvoiceDetailDialogProps({ open: false });
};
const handleSelectAllClick = (rows, setInvoicesSelected) => (event) => {
    if (event.target.checked) {
        const newSelected = rows.map(row => row.id);
        setInvoicesSelected(newSelected);
        return;
    }
    setInvoicesSelected([]);
};
const handleClick = (invoiceId, invoicesSelected, setInvoicesSelected) => (_) => {
    const selectedIndex = invoicesSelected.indexOf(invoiceId);
    let newSelected = [];
    if (selectedIndex === -1) {
        newSelected = newSelected.concat(invoicesSelected, invoiceId);
    }
    else if (selectedIndex === 0) {
        newSelected = newSelected.concat(invoicesSelected.slice(1));
    }
    else if (selectedIndex === invoicesSelected.length - 1) {
        newSelected = newSelected.concat(invoicesSelected.slice(0, -1));
    }
    else if (selectedIndex > 0) {
        newSelected = newSelected.concat(invoicesSelected.slice(0, selectedIndex), invoicesSelected.slice(selectedIndex + 1));
    }
    setInvoicesSelected(newSelected);
};
export const InvoicesList = () => {
    const dispatch = useAppDispatch();
    const table = useAppSelector($invoiceListSlice.selector.invoiceTable);
    const isInvoiceTransmitting = useAppSelector($invoiceTransmissionSlice.selector.isLoading);
    const isTableLoading = useAppSelector($invoiceListSlice.selector.isLoading);
    const invoiceIdDeleting = useAppSelector($invoiceDeletionSlice.selector.invoiceIdDeleting);
    const [isNewInvoiceDialogOpen, setNewInvoiceDialogOpen] = useState(false);
    const [invoiceDetailDialogProps, setInvoiceDetailDialogProps] = useState({
        open: false,
    });
    const [isBulkEditDialogOpen, setIsBulkEditDialogOpen] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [invoicesSelected, setInvoicesSelected] = React.useState([]);
    useEffect(() => {
        dispatch($InvoiceToTransmitTab.startInvoiceToTransmitTab());
        return () => {
            dispatch($InvoiceToTransmitTab.endInvoiceToTransmitTab());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (_jsxs(_Fragment, { children: [_jsx(Authorization, { permissions: [PERMISSION.VIEW_INVOICE_LIST_SWITCH], children: _jsx(Grid, { container: true, item: true, justifyContent: "flex-end", children: _jsx(InvoiceSwitch, {}) }) }), isTableLoading ? (_jsx(Box, { style: { display: 'flex', justifyContent: 'center' }, children: _jsx(CircularProgress, {}) })) : (_jsxs(Box, { style: { marginBottom: 77 }, children: [invoiceDetailDialogProps.open && (_jsx(InvoiceDetailDialog, Object.assign({ onClose: handleCloseInvoiceDetailDialog(setInvoiceDetailDialogProps) }, invoiceDetailDialogProps))), isNewInvoiceDialogOpen && (_jsx(NewInvoiceDialog, { setIsOpen: setNewInvoiceDialogOpen, setEditMode: setEditMode, editMode: editMode, open: isNewInvoiceDialogOpen })), isBulkEditDialogOpen && (_jsx(BulkEditDialog, { open: isBulkEditDialogOpen, onClose: toggleModal(setIsBulkEditDialogOpen), resetSelection: () => setInvoicesSelected([]), invoiceListId: invoicesSelected })), either.isRight(table) ? (_jsxs(_Fragment, { children: [_jsx(EnhancedTableToolbar, { invoicesSelected: invoicesSelected.length, onEdit: toggleModal(setIsBulkEditDialogOpen) }), _jsx(TableContainer, { component: Paper, style: { marginBottom: 16 }, children: _jsxs(Table, { children: [_jsx(TableHead, { children: pipe(table.right.headers, readonlyArray.mapWithIndex((rowKey, header) => (_jsxs(TableRow, { children: [_jsx(Authorization, { permissions: [PERMISSION.EDIT_INVOICE], children: _jsx(TableCell, { padding: "checkbox", children: _jsx(Checkbox, { color: "primary", indeterminate: invoicesSelected.length > 0 &&
                                                                    invoicesSelected.length < table.right.body.length, checked: table.right.body.length > 0 &&
                                                                    invoicesSelected.length === table.right.body.length, onChange: handleSelectAllClick(table.right.body, setInvoicesSelected) }) }) }), _jsx(TableCell, { children: _jsx("strong", { children: header.approved }) }), _jsx(Authorization, { permissions: [PERMISSION.VIEW_ASSIGNEE_TABLE_ROW], children: _jsx(TableCell, { children: _jsx("strong", { children: header.assignee }) }) }), _jsx(TableCell, { children: _jsx("strong", { children: header.amount }) }), _jsx(TableCell, { children: _jsx("strong", { children: header.enasarco }) }), _jsx(TableCell, { children: _jsx("strong", { children: header.month }) }), _jsx(TableCell, {})] }, rowKey)))) }), _jsx(TableBody, { children: pipe(table.right.body, readonlyArray.mapWithIndex((rowKey, invoice) => (_jsxs(TableRow, { selected: invoicesSelected.indexOf(invoice.id) !== -1, children: [_jsx(Authorization, { permissions: [PERMISSION.EDIT_INVOICE], children: _jsx(TableCell, { padding: "checkbox", sx: { cursor: 'pointer' }, onClick: handleClick(invoice.id, invoicesSelected, setInvoicesSelected), children: _jsx(Checkbox, { color: "primary", checked: invoicesSelected.indexOf(invoice.id) !== -1 }) }) }), _jsx(TableCell, { style: { width: 20 }, children: invoice.approved && (_jsx(ApprovedFilled, { style: {
                                                                width: 20,
                                                                height: 20,
                                                                verticalAlign: 'middle',
                                                            } })) }), _jsx(Authorization, { permissions: [PERMISSION.VIEW_ASSIGNEE_TABLE_ROW], children: _jsx(TableCell, { children: invoice.fullName }) }), _jsx(TableCell, { children: invoice.amount }), _jsxs(TableCell, { children: [invoice.hasEnasarco, pipe(invoice.enasarcoDeficit, option.match(constant(null), constant(_jsx(Tooltip, { title: "Hai superato il massimale Enasarco", children: _jsx(IconButton, { size: "large", children: _jsx(ErrorOutlinedIcon, { color: "error" }) }) }))))] }), _jsx(TableCell, { children: invoice.month }), _jsx(TableCell, { children: _jsxs(Grid, { container: true, justifyContent: "flex-end", spacing: 2, flexWrap: "nowrap", alignItems: "center", children: [_jsx(Authorization, { permissions: [PERMISSION.TRANSMIT_INVOICE], children: InvoiceStatusCodeUtils.READY ===
                                                                        invoice.statusCode && (_jsx(Grid, { item: true, children: _jsx(Button, { disabled: isInvoiceTransmitting, variant: "contained", size: "small", onClick: handleInvoice($InvoiceTransmission.transmitInvoice(invoice.id), dispatch), children: "Invia" }) })) }), _jsx(Authorization, { permissions: [PERMISSION.EDIT_INVOICE], children: _jsx(Grid, { item: true, children: _jsx(Button, { onClick: handleEditInvoice(invoice.id, $InvoiceEdit.startInvoiceEdit, dispatch, setNewInvoiceDialogOpen, setEditMode), style: squareButtonStyle, variant: "outlined", children: _jsx(EditIcon, {}) }) }) }), _jsx(Authorization, { permissions: [PERMISSION.DELETE_INVOICE], children: _jsx(Grid, { item: true, children: _jsx(Button, { style: squareButtonStyle, variant: "text", onClick: handleDeleteInvoice($InvoiceDeletion.deleteInvoice(invoice.id), dispatch), disabled: isDeleteButtonDisabled(invoice.id)(invoiceIdDeleting), children: _jsx(DeleteOutlineIcon, {}) }) }) }), _jsx(Authorization, { permissions: [PERMISSION.VIEW_INVOICE_DETAIL], children: _jsx(Grid, { item: true, onClick: handleInvoiceDetail(setInvoiceDetailDialogProps, invoice.id), children: _jsx(KeyboardArrowRightIcon, { style: {
                                                                                color: '#3377F6',
                                                                                cursor: 'pointer',
                                                                            } }) }) })] }) })] }, rowKey)))) })] }) })] })) : (_jsx(Authorization, { permissions: [PERMISSION.VIEW_EMPTY_LIST_IMG], children: _jsx(EmptyList, { message: "Nessuna fattura disponibile" }) })), _jsx(Container, { style: {
                            paddingBottom: 20,
                            paddingTop: 20,
                            position: 'fixed',
                            height: 77,
                            bottom: 0,
                            left: '50%',
                            transform: 'translateX(-50%)',
                        }, children: _jsxs(Grid, { container: true, justifyContent: "space-between", wrap: "nowrap", children: [_jsx(Authorization, { permissions: [PERMISSION.CREATE_INVOICE], children: _jsx(Grid, { item: true, children: _jsx(Button, { variant: "contained", onClick: toggleModal(setNewInvoiceDialogOpen), startIcon: _jsx(AddIcon, {}), children: "Crea fattura" }) }) }), _jsx(Authorization, { permissions: [PERMISSION.TRANSMIT_INVOICE], children: _jsx(Grid, { item: true, children: _jsx(Button, { variant: "contained", onClick: handleTransmitEveryInvoice($InvoiceTransmission.transmitInvoiceBulk, dispatch), children: "Invia fatture" }) }) })] }) })] }))] }));
};
