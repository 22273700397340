import { jsx as _jsx } from "react/jsx-runtime";
import { InputAdornment } from '@mui/material';
import TextField from '@mui/material/TextField';
import { NumericFormat } from 'react-number-format';
import { makeStyles } from 'tss-react/mui';
const useStyles = makeStyles()(() => ({
    label: { backgroundColor: '#fff', paddingRight: 5 },
}));
export const CurrencyField = ({ fieldLabel, required = false, style = {}, disabled = false, allowNegative = false, name, value, onValueChange, }) => {
    const { classes } = useStyles();
    return (_jsx(NumericFormat, { name: name, value: value, fullWidth: true, style: Object.assign({}, style), disabled: disabled, required: required, customInput: TextField, variant: "outlined", margin: "dense", label: fieldLabel, InputProps: {
            startAdornment: _jsx(InputAdornment, { position: "start", children: "\u20AC" }),
        }, InputLabelProps: {
            classes: {
                root: classes.label,
            },
        }, thousandSeparator: ".", allowNegative: allowNegative, decimalSeparator: ",", onValueChange: onValueChange }));
};
