import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import CloseIcon from '@mui/icons-material/Close';
import { Box, Divider, Button, Typography, IconButton } from '@mui/material';
import React from 'react';
import { tss } from 'tss-react';
import { Row } from '../atoms/Row';
const useStyles = tss.create(() => ({
    container: { padding: 12 },
    title: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '16px',
    },
    footer: {
        display: 'flex',
        justifyContent: 'center',
    },
    box: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'space-between',
        width: '332px',
    },
    content: { display: 'flex', flexDirection: 'column' },
}));
export function PaperworkDetail({ onClose }) {
    const { classes, cx } = useStyles();
    return (_jsxs(Box, { className: classes.box, children: [_jsx("div", { className: classes.content, children: _jsxs("div", { className: classes.container, children: [_jsxs("div", { className: classes.title, children: [_jsx(Typography, { variant: "h2", children: "Gestione pratica" }), _jsx(IconButton, { onClick: onClose, children: _jsx(CloseIcon, {}) })] }), _jsx(Row, { label: "Id pratica", value: "1" }), _jsx(Row, { label: "Duplicata dalla pratica", value: "2" }), _jsx(Row, { label: "Data di creazione", value: "3" }), _jsx(Row, { label: "Presa in carico", value: "4" }), _jsx(Row, { label: "Creata da", value: "5" }), _jsx(Row, { label: "Modificata da", value: "6" }), _jsx(Row, { label: "In carico a", value: "7" }), _jsx(Row, { label: "Rubinetto", value: "8" })] }) }), _jsxs("div", { children: [_jsx(Divider, {}), _jsx("div", { className: cx(classes.container, classes.footer), children: _jsx(Button, { variant: "contained", onClick: onClose, children: "Chiudi" }) })] })] }));
}
